
import { UserService } from '@/services';
import { defineComponent } from 'vue';

export default defineComponent({
	setup() {
		const isAdmin = UserService.isAdmin;
		return { isAdmin };
	}
});
